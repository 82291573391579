.swal2-popup {
  min-height: 16em;
}

.swal2-popup .swal2-content {
  font-size: 3rem !important;
}

.swal2-popup .swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #023767 !important;
  color: #fff;
  font-size: 1.5rem;
}
