.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 7vmin;
}

.App-footer {
  background-color: $light-blue;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: $m-size;
  color: $dark-blue;
}

.App-link {
  color: $dark-blue;
  &:hover {
    color: white;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
