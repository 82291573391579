* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  background: $light-blue;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $m-size;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}
