// Colors
$off-black: #00172b;
$dark-blue: #023767;
$blue: #075397;
$light-blue: #94bae9;
$off-white: #f7f7f7;
$purple: #8357c5;
// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;
